.foodsButton {
  /* display: flex;
  justify-content: space-evenly; */
  /* margin: 0 14.2% 10px 14.2%; */
  min-width: 335px;
  max-width: 550px;
  margin: 0 auto;
}

.container {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 5px;
  display: flex;
  min-width: 335px;
  max-width: 550px;
  min-height: 100px;
}

.thing {
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.table {
  width: 550px;
  margin: 0 auto;
}

.clearBtn {
  margin-top: 5px;
  margin-bottom: 15px;
}

.mt-3 {
  margin-top: 0px !important;
  float: right;
  margin-bottom: 15px;
}
.generateBtn {
  float: right;
  margin-top: 5px;
  margin-bottom: 15px;
}
/* .createBtn {
  margin-left: 28.2%;
  
} */
